@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.ad-container {
  height: 318px;
  margin: 0 auto;
  overflow: hidden;
  padding: 34px 0;
  text-align: center;

  @include desktop-size {
    max-width: $desktop-max-width;
  }
}
