@import "/scss/variables/media-queries";

@mixin tablet-size {
  @media only screen and (min-width: $medium) {
    @content;
  }
}

@mixin desktop-size {
  @media only screen and (min-width: $large) {
    @content;
  }
}

@mixin xl-desktop-size {
  @media only screen and (min-width: $xlarge) {
    @content;
  }
}

@mixin screen-min-width($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}
