@import "./variables/fonts";
@import "./variables/colors";

html {
  box-sizing: border-box;
  box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  border: 0;
  box-sizing: inherit;
  box-sizing: inherit;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
}

.overflow-for-subs-onboarding {
  overflow: hidden;
}

.overflow-for-subs-onboarding .tp-container-inner,
.overflow-for-subs-onboarding .tp-container-inner iframe {
  height: 100% !important;
}

.sr-only {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
